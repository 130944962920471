import React, { useState }  from 'react';
import styled from 'styled-components'
import { Menu, Dropdown, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
// import { ReactComponent as Logo } from '../../Assets/logo/logo-yec.png';
import Logo  from '../../../Assets/logo/logoyec.png';
import firebase from 'firebase/app'
import { FirebaseAuthConsumer } from "@react-firebase/auth";

const NavStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding-right: 20px;
    padding-left: 20px;
    height: 90px;
    // font-family: ${props => props.theme.font};
    // width: 100%;
    // max-width: 1440px;
`

const LogoStyle = styled.div`
    display: flex;
    margin: 15px 5px 5px 20px;
    /* width: 200px; */
    font-size: 20px;
    text-transform: uppercase;
    color: #A3CB38;
`
const LogoImg = styled.img`
    width: 100px;
    /* height: 50px; */
    /* background: red; */
`
const LogoBox = styled.div`
    display: flex;
    width: 300px;
    height: 20px;
`
const LogoTxtYoga = styled.div`
    margin-left: 5px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: ${props => props.theme.grey4};
`
const LogoTxtEm = styled.div`
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: ${props => props.theme.orange};
`
const LogoTxtCps = styled.div`
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: ${props => props.theme.grey4};
`
const LogoTxtCom = styled.div`
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: ${props => props.theme.blue};
`

const ItemStyle = styled.div`
    font-size: 0.8em;
    font-weight: bold;
    color: grey;
    text-transform: uppercase;
`
// const LogoKey = styled(Logo)`
//     height: 30px;
//     margin-top: -6px;
// `
const Avatar = styled.div`
    color: white;
    font-size: 13px;
    text-transform: uppercase;
    padding: 6px 8px;
    border-radius: 50%;
    background: ${props => props.theme.orange};
`




const Nav = () => {
    const [activeItem, setActiveItem] = useState('Inicial');


    return (
        <FirebaseAuthConsumer>
  		{({ isSignedIn, user, providerId }) => (
        <NavStyle>
            <Menu.Menu>
                    {/* <Menu.Item as={Link} to='/' name='Inicial' active={activeItem === ''} onClick={(e, { name }) => setActiveItem(name)}><LogoStyle><LogoKey />ComoDigitar<Com>.com</Com></LogoStyle></Menu.Item> */}
                    <Menu.Item as={Link} to='/' name='Inicial' active={activeItem === ''} onClick={(e, { name }) => setActiveItem(name)}>
                    <LogoBox>
                        {/* <LogoKey /> */}
                        <LogoTxtYoga>YOGA</LogoTxtYoga>
                        <LogoTxtEm>EM</LogoTxtEm>
                        <LogoTxtCps>CAMPINAS</LogoTxtCps>
                        <LogoTxtCom>.COM</LogoTxtCom>
                    </LogoBox>
                    </Menu.Item>
            </Menu.Menu>  

            {/* <LogoPlace></LogoPlace> */}
            <LogoImg src={Logo} alt="logo" />
            
            <Menu secondary pointing>
                <Menu.Menu >
                        <Menu.Item as={Link} to='/' name='Inicial' active={activeItem === 'Inicial'} onClick={(e, { name }) => setActiveItem(name)}><ItemStyle>Inicial</ItemStyle></Menu.Item>
                        <Menu.Item as={Link} to='/artigos' name='Artigos' active={activeItem === 'Artigos'} onClick={(e, { name }) => setActiveItem(name)}><ItemStyle>Artigos</ItemStyle></Menu.Item>
                        <Menu.Item as={Link} to='#' name='Profissionais' active={activeItem === 'Profissionais'} onClick={(e, { name }) => setActiveItem(name)}><ItemStyle>Profissionais</ItemStyle></Menu.Item>
                        <Menu.Item as={Link} to='/escolas-de-yoga-em-campinas' name='Escolas' active={activeItem === 'Escolas'} onClick={(e, { name }) => setActiveItem(name)}><ItemStyle>Escolas</ItemStyle></Menu.Item>
                        {user && user.uid &&
                            <Menu.Item>
                                <ItemStyle>
                                    <Dropdown trigger={<Avatar>{user.email.substring(0,1)}</Avatar>} icon={null} position='bottom left'>
                                        <Dropdown.Menu>
                                            <Dropdown.Header>logado como {user.email}</Dropdown.Header>
                                            <Dropdown.Item>
                                                <Link to='/dashboard'>DASHBOARD</Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Button onClick={() => { firebase.auth().signOut();}}>
                                                    Sair
                                                </Button>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ItemStyle>
                            </Menu.Item>
                            }
                </Menu.Menu>
        </Menu>
      </NavStyle>
      )}
      </FirebaseAuthConsumer>
    )
}

export default Nav;