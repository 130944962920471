import React, {useEffect} from 'react';
import styled from 'styled-components'
import { Map, CircleMarker, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


const MainStyle = styled.div`
    position: relative;
    background: white;
	min-height: 400px;
    margin: 0px 0px 10px 0px;
    // padding: 30px 60px 30px 60px;
    color: ${props => props.theme.color};
    font-family: ${props => props.theme.font};
    width: 100%;
    height: 100vh;
`
const PageTitle = styled.h1`
    position: absolute;
    z-index: 900;
    top: 10px;
    left: 70px;
    padding: 5px;
    width: 350px;
    text-align: center;
    background: white;
    font-size: 18px;
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.grey6};
    // margin-bottom: -100px;
	
`
const Columns = styled.div`
    display: flex;
`
const MapContainer = styled.div`
    width: 100%;
    height: calc(100vh - 50px);
`
const List = styled.div`
    width: 300px;
    height: calc(100vh - 50px);
    padding: 10px;
    background: #fad390;
    overflow: auto;
`
const ListItem = styled.div`
    margin-bottom: 10px;
    color: ${props => props.theme.grey7};
    background: #FBDFAE;
    padding: 7px;
`
const ItemTitle = styled.div`
    font-weight: 500;
`
const ItemTel = styled.div`
`



const escolas = [
    {titulo: 'Agni Ayurveda', position: [-22.890909, -47.073482], tel: '(19) 97153-0264'},
    {titulo: 'Studio do Bem', position: [-22.896358, -47.047790], tel: '(19) 99606-5220'},
    {titulo: 'Padma Bhavam', position: [-22.888834, -47.067479], tel: '(19) 4141-6955'},
    {titulo: 'Núcleo Vidhya', position: [-22.879591, -47.056585], tel: '(19) 998420981'},
    {titulo: 'Círculo de Meditação em Campinas', position: [-22.877471, -47.053499], tel: '(19) 3326-4799'},
    {titulo: 'Brahma Kumaris', position: [-22.887440, -47.042299], tel: '(19) 3241-7480'},
    {titulo: 'Auto Conexão', position: [-22.872396, -47.041861], tel: '(19) 3307-7735'},
    {titulo: 'GrupoNiu Yoga', position: [-22.825877, -47.104040], tel: '(19) 99302-0600'},
    {titulo: 'Studio Yoga Campinas', position: [-22.886667, -47.065232], tel: '(19) 99197-7877'},
    {titulo: 'Yoga Hípica', position: [-22.898432, -47.020104], tel: '(19) 3794-6024'},
    {titulo: 'Espaço Shanti', position: [-22.893516, -47.053021], tel: '(19) 99497-1331'},
    {titulo: 'Espaço Xamar', position: [-22.795534, -47.075545], tel: '(19) 98133-0761'},
    {titulo: 'Salute Per Te', position: [-22.898275, -47.059112], tel: '(19) 32313218'},
    {titulo: 'Centro de Artes Espirituais', position: [-22.918250, -47.052305], tel: '(19) 99165-9044'}
]

const Escolas = () => {


    useEffect(()=>{
        window.document.title = 'Escolas de Yoga em Campinas - Yoga em Campinas'
        window.scrollTo(0,0)

    }, [])

    const EscolasIcon = L.icon({
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/icones%2Fmaps%2Fmap-pin.svg?alt=media&token=5d486992-70a5-417d-ae61-dee19dbe77ba',
        shadowUrl: iconShadow,
        iconSize: [30, 45],
        // shadowSize: [25, 45],
        popupAnchor: [0, -37],
        iconAnchor: [15, 45],
        shadowAnchor: [14, 47],
    });

    return (
        <MainStyle>

        <PageTitle>Escolas de Yoga em Campinas</PageTitle>

        <Columns>

            <MapContainer>

                <Map
                    style={{ height: "100%", width: "100%" }}
                    zoom={14}
                    center={[-22.893739, -47.061727]}
                >
                    <TileLayer 
                        url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png"
                        attribution='<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>'                
                        minZoom={1}
                        maxZoom={19}
                    />

                    {escolas.map((item, index) => 
                                    <Marker key={item.position[0]} position={[item.position[0], item.position[1]]} icon={EscolasIcon}>
                                        <Popup >
                                            <strong>{item.titulo}</strong>
                                            <div>{item.tel}</div>
                                        </Popup>
                                    </Marker>

                                )}

                </Map>

            </MapContainer>

            <List>
                {escolas.map(item => 
                    <ListItem>
                        <ItemTitle>{item.titulo}</ItemTitle>
                        <ItemTel>{item.tel}</ItemTel>
                    </ListItem>
                )}

            </List>

        </Columns>

        </MainStyle>
    )
}

export default Escolas;