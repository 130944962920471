import React from 'react';
import styled from 'styled-components'
import { Link } from "react-router-dom";


const CardCanvas = styled.div`
width: 100%;
height: 300px;
/* border: 1px solid grey; */
`
const CardImage = styled.img`
background: grey;
width: 100%;
height: 150px;
object-fit: cover;
`
const CardTitle = styled.div`
font-family: ${props => props.theme.font};
font-weight: 600;
font-size: 20px;
padding: 20px 10px 20px 0px;
`
const CardTxt = styled.div`
font-family: ${props => props.theme.font};
font-size: 16px;
padding: 0px 10px 0px 0px;
`

const Card = ({title, resumo, imgcard, id}) => {

    return (
        <CardCanvas>
            <Link to={`/artigo/${id}`}>
                <CardImage 
                    src={imgcard}
                    // srcSet="
                    // https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/artigos%2Ffeiras%20na%20unicamp%2Fartigofeiramapa1.png?alt=media&token=f2ef5593-d4df-45c2-acc9-a653db35e053 200w,
                    // https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/artigos_images%2Fbarao-geraldo%2Fbarao-geraldo_dwkyyg_c_scale%2Cw_456.jpg?alt=media& token=c8fbc71f-f01f-46a5-b172-d19fd70c0dcf 456w,
                    // https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/artigos_images%2Fbarao-geraldo%2Fbarao-geraldo_dwkyyg_c_scale%2Cw_613.jpg?alt=media& token=a607ac25-26d6-4fa8-b16d-45c83f35e173 613w,
                    // https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/artigos_images%2Fbarao-geraldo%2Fbarao-geraldo_dwkyyg_c_scale%2Cw_700.jpg?alt=media& token=c8c9eda1-339f-4083-ad69-2fdfdfdd3d2b 700w"
                    
                />
                <CardTitle>{title}</CardTitle>
                {resumo && <CardTxt>{resumo.toString().replace(/^(.{100}[^\s]*).*/, "$1") + '...'}</CardTxt>}
            </Link>
        </CardCanvas>
        
    )
}

export default Card;