import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
// import CardTop from './CardTop'
import Card from './Card'
// import SectionNews from './SectionNews'

import firebase from 'firebase/app';
require("firebase/firestore");

const MainStyle = styled.div`
    background: white;
	min-height: 400px;
    margin: 0px 0px 10px 0px;
    padding: 30px 60px 30px 60px;
    color: ${props => props.theme.color};
    font-family: ${props => props.theme.font};
    width: 100%;
`
const PageTitle = styled.div`	
    margin: 40px 0px 60px 0px;
    font-size: 50px;
    color: ${props => props.theme.grey4};
    font-family: ${props => props.theme.font};
    width: 100%;
`
const MainGrid = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 270px 270px;
    grid-template-areas:
    'box1 box1 box2 box3'
    'box4 box5 box2 box3';
    width: 100%;
    margin-bottom: 40px;
`
const Box1 = styled.div`
    grid-area: box1;
    width: 100%;
    height: 100%;
    background: grey; 
`
const Box2 = styled.div`
    grid-area: box2;
    width: 100%;
    height: 100%;
    background: grey;
`
const Box3 = styled.div`
    grid-area: box3;
    width: 100%;
    height: 100%;
    background: grey;
    
`
const Box4 = styled.div`
    grid-area: box4;
    width: 100%;
    height: 100%;
    background: grey;
`
const Box5 = styled.div`
    grid-area: box5;
    width: 100%;
    height: 100%;
    background: grey;
`
const Box6 = styled.div`
    grid-area: box6;
    width: 100%;
    height: 100%;
    background: grey;
`
const SectionCategory = styled.div`
    margin-bottom: 40px;
`
const TitleCategory = styled.div`
    // text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.orange};
    // border-left: 3px solid ${props => props.theme.orange};
    // padding-left: 10px;
    line-height: 0.8em;
    padding-bottom: 0px;
    margin-bottom: 10px;
`
const BoxesCategory = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 350px;
    width: 100%;
    padding-top: 10px;
`


const GuiaBarao = () => {

    const [dicasCards, setDicasCards] = useState([]);
    const [praticaCards, setPraticaCards] = useState([]);
    



    useEffect(() => {
        document.title = `Artigos sobre Yoga - Yoga em Campinas`;
        
		const artigosRef = firebase.firestore().collection("artigos")
        
        // Lendo os últimos Artigos Categoria "Dicas"
        let dicasTemp = []
        artigosRef.where("published", "==", true).where("categoria", "==", 'dicas').get().then((snapshot) => {
			snapshot.forEach((doc) => {
				dicasTemp.push(doc.data())
			});
			setDicasCards(dicasTemp)
        })

        // Lendo os últimos Artigos Categoria "Prática"
        let praticaTemp = []
        artigosRef.where("published", "==", true).where("categoria", "==", 'pratica').get().then((snapshot) => {
			snapshot.forEach((doc) => {
				praticaTemp.push(doc.data())
			});
			setPraticaCards(praticaTemp)
        })


    }, []);





    return (
        <MainStyle>
            {/* <MainGrid>
                <Box1>
                    <CardTop 
                        title='Exercitando-se em Barão Geraldo' 
                        tag='Estilo de Vida' 
                        image='https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/guiabarao%2Fexercitando-se%2Fexercitando-card.jpg?alt=media&token=ae5bb950-e1db-4458-b260-c177d2f4129a' 
                        link='/barao-geraldo/exercitando-se-em-barao-geraldo'
                    />
                </Box1>
                <Box2>
                    <CardTop 
                        title='Bares em Barão Geraldo' 
                        tag='Gastronomia' 
                        image='https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/guiabarao%2Fbares-em-barao%2Fbares-em-barao-card.jpg?alt=media&token=834ee550-4ddb-4090-8e67-9680be86230c' 
                        link='/barao-geraldo/bares-em-barao-geraldo'
                    />
                </Box2>
                <Box3>
                    <CardTop 
                        title='Onde comer em Barão Geraldo' 
                        tag='Estilo de Vida' 
                        image='https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/guiabarao%2Fonde-comer-em-barao%2Fonde-comer-em-barao.jpg?alt=media&token=af9a896e-5cfc-481e-ae4a-02c21f4ccc67' 
                        link='/barao-geraldo/gastronomia-em-barao-geraldo'
                    />
                </Box3>
                <Box4>
                    <CardTop 
                        title='Feiras em Barão Geraldo' 
                        tag='Eventos' 
                        image='https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/guiabarao%2Ffeiras-em-barao%2Ffeiras-em-barao.jpg?alt=media&token=d35009b0-0661-44ed-aa5e-0f9af9d74212' 
                        link='/barao-geraldo/feiras-em-barao-geraldo'
                        />
                </Box4>
                <Box5>
                    <CardTop 
                        title='Festas em Barão Geraldo' 
                        tag='Lazer' 
                        image='https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/guiabarao%2Ffestas-em-barao%2Ffestas-em-barao.jpg?alt=media&token=371f77d8-a94f-4c75-ab1b-f23f0831df39' 
                        link='barao-geraldo/festas-universitarias-unicamp'
                        />
                </Box5>
            </MainGrid> */}
            <PageTitle>
                Leia, relaxe e pratique
            </PageTitle>

            <SectionCategory>
                <TitleCategory>Dicas de Yoga</TitleCategory>
                <BoxesCategory>
                    { dicasCards.map(item => 
                         <Card title={item.title} resumo={item.resumo} id={item.id} imgcard={item.imgcard}/>
                    )}
                </BoxesCategory>
            </SectionCategory>
            

            {/* <SectionNews /> */}


            <SectionCategory>
                <TitleCategory>Prática de Yoga</TitleCategory>
                <BoxesCategory>
                    { praticaCards.map(item => 
                         <Card title={item.title} resumo={item.resumo} id={item.id} imgcard={item.imgcard}/>
                    )}
                </BoxesCategory>
            </SectionCategory>


            {/* <SectionCategory>
                <TitleCategory>Moradias</TitleCategory>
                <BoxesCategory>
                    { moradiaCards.map(item => 
                         <Card title={item.title} resumo={item.resumo} id={item.id} cardImg={item.cardImg}/>
                    )}
                </BoxesCategory>
            </SectionCategory> */}


        </MainStyle>
    )
}

export default GuiaBarao