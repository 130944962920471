import './Assets/css/font-imports.css'
import './Assets/css/global-css.css'
import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {ThemeProvider} from 'styled-components'
import 'semantic-ui-css/semantic.min.css'
import { GlobalStyle } from './Assets/css/global-styles'
import firebase from "firebase/app";
import "firebase/auth";
import {
	FirebaseAuthProvider,
	FirebaseAuthConsumer,
	IfFirebaseAuthed,
	IfFirebaseAuthedAnd
  } from "@react-firebase/auth";

// import DashBoard from './Components/DashBoard/DashBoard'
import FrontPage from './Components/FrontPage/FrontPage'
import Layout from './Components/Layout/Layout'
import Artigos from './Components/Artigos/Artigos'
import Escolas from './Components/Escolas/Escolas'
import ArticleView from './Components/ArticleView/ArticleView'
const DashBoard = lazy(() => import('./Components/DashBoard/DashBoard'));



  var config = {
    apiKey: 'AIzaSyCirllGYF4-Ime-P5THqVGtSBkqOgdaSDE',
	authDomain: 'yoga-em-cps.firebaseapp.com',
	projectId: "yoga-em-cps",
    // databaseURL: '<your-database-url>',
    // storageBucket: '<your-storage-bucket>'
  };


function App() {
  return (
		<FirebaseAuthProvider firebase={firebase} {...config}>
			<Router>
				<ThemeProvider theme={theme}>
					<Layout>
						<GlobalStyle />
						<Suspense fallback={<div>Loading...</div>}>
						<Switch>
							<Route path="/" exact component={FrontPage} />
							<Route path="/artigo/:id" component={ArticleView} />
							<Route path="/dashboard/:cat" component={DashBoard} />
							<Route path="/dashboard" component={DashBoard} />
							<Route path="/artigos" exact component={Artigos} />
							<Route path="/escolas-de-yoga-em-campinas" exact component={Escolas} />
						</Switch>
						</Suspense>
					</Layout>
				</ThemeProvider>
			</Router>
		</FirebaseAuthProvider>
  );
}


const theme = {
	font: 'Montserrat',
	offwhite: '#FAFBFC',
	orange: '#EF7E55',
	green: '#A6CF41',
	blue: '#2799D0',
	darkgrey: '#484848',
	grey1: '#E5E5E5',
	grey2: '#CCCCCC',
	grey3: '#B2B2B2',
	grey4: '#999999',
	grey5: '#7F7F7F',
	grey6: '#666666',
	grey7: '#575757',
	grey8: '#444444',
	grey9: '#333333',
	grey10: '#222222',
  };

export default App;
