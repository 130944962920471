import React from 'react';
import styled from 'styled-components'
// import { Image } from 'semantic-ui-react'
import { Link } from "react-router-dom";

const ItemCanvas = styled.div`
    position: relative;
    background: white;
    display: flex;
    /* border: 1px solid lightgrey; */
    width: 100%;
    height: 100%;
`

const TitleStyle = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: 40px; */
    padding: 15px;
    background: rgba(0,0,0,0.5);
    z-index: 2;
    /* color: ${props => props.theme.grey6}; */
    color: white;
    font-size: 18px;
    /* font-weight: bold; */
    /* font-family: ${props => props.theme.font}; */

    @media (max-width: 600px) {
        font-size: 1em;
        padding: 10px;

    }
`


const Image = (props) => {

    const ImageStyle = styled.div`
        /* width: 250px; */
        width: 100%;
        height: 100%;
        background-image: url(${props.cardimage});
        background-size: cover;
        background-color: ${props => props.theme.offwhite};
    `

    return (
        <ImageStyle />
    )
    
}


const ItemCard = (props) => {

    return (
        <Link to={props.link}>
        <ItemCanvas>
            <Image cardimage={props.cardimage}/>
            <TitleStyle>{props.title}</TitleStyle>
            {/* <TextBoxStyle>
                <TitleStyle>{props.title}</TitleStyle>
                <SubTitleStyle>
                    {props.type.charAt(0).toUpperCase() + props.type.slice(1)} 
                </SubTitleStyle>
                <ResumoStyle>
                    {props.desc}
                </ResumoStyle>
            </TextBoxStyle> */}
        </ItemCanvas>
        </Link>

    )
}

export default ItemCard;