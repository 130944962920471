import React from 'react';
import styled from 'styled-components'
import Nav from './Desktop/Nav'
import NavMobile from './Mobile/NavMobile'
import Footer from './Desktop/Footer'
import MediaQuery from 'react-responsive'
import FooterMobile from './Mobile/FooterMobile'


const CanvasStyle = styled.div`
    background: #f1f2f6;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const MainStyle = styled.div`
    width: 100%;
    max-width: 1440px;
`


const Layout = (props) => {

    return (
        <CanvasStyle>
            <MainStyle>
                <MediaQuery maxWidth={600}>
                    <NavMobile />
                </MediaQuery>
               
                <MediaQuery minWidth={601}>
                    <Nav />
                </MediaQuery>

                    {props.children}

                <MediaQuery maxWidth={600}>
                    <FooterMobile />
                </MediaQuery>

                <MediaQuery minWidth={601}>
                    <Footer />
                </MediaQuery>
            </MainStyle>

        </CanvasStyle>
    )

}

export default Layout