import React from 'react';
import styled from 'styled-components'
import { Link } from "react-router-dom";


const FooterStyle = styled.div`
    min-height: 200px;
    background: ${props => props.theme.orange};
    padding: 30px;
`
const ColumnBox = styled.div`
    display: flex;
    margin-left: 50px;
    height: 100px;
`
const Column = styled.div`
    padding: 10px;
    /* width: 160px; */
    margin-top: 20px;
    margin-right: 20px;
`
const Item = styled.div`
    color: white;
    font-size: 16px;
    margin-bottom: 10px;
    margin-right: 20px;
`
const BottomFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    /* justify-content: space-between; */
    color: white;
    font-weight: 500;
    margin-right: 50px;

`
const BottomItem = styled.div`
    margin-top: 50px;
    margin-right: 30px;
    color: white;
    font-weight: 600;
`
const LogoFooter = styled.div`
    display: flex;
    align-items: flex-end;
`
const Logo = styled.img`
    margin-left: 10px;
`
const BottomMenu = styled.div`
    display: flex;
   
    
`




const Footer = () => {

    return(
        <FooterStyle>
            <ColumnBox>
                {/* <Column>
                    <Link to='#'><Item>Onde Comer</Item></Link>
                    
                </Column>
    
                <Column>
                    <Link to='#'><Item>Encontre</Item></Link>
                    
                </Column>
                <Column>
                    <Link to='#'><Item>Animais Perdidos</Item></Link>
                    
                </Column>

                <Column>
                    <Link to='#'><Item>Dias das Feiras</Item></Link>
                </Column>

                <Column>
                    <Item>Participe da nossa Newsletter</Item>
                </Column> */}
                
            </ColumnBox>

            <BottomFooter>
                {/* <LogoFooter>
                    <Link to='/'>
                        <Logo src='https://firebasestorage.googleapis.com/v0/b/morando-em-barao.appspot.com/o/logos_mb%2Fmb-logo.png?alt=media&token=98557b9e-c22e-49f3-a50a-76345e8812d8'/>
                    </Link>
                </LogoFooter> */}
                
                <BottomMenu>
                    <BottomItem>Facebook</BottomItem>
                    <BottomItem>Instagram</BottomItem>
                    <BottomItem>Medium</BottomItem>
                    <BottomItem>Contato</BottomItem>
                </BottomMenu>
            </BottomFooter>
        </FooterStyle>
        )

}

export default Footer;