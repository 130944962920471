import React from 'react';
import styled from 'styled-components'
import ItemCard from './ItemCard'
import { Link } from 'react-router-dom'
// import firebase from "firebase/app";
// require("firebase/firestore");


const MainFrontStyle = styled.div`
    background: white;
	min-height: calc(100vh - 70px);
    padding: 100px 40px 0px 40px;
    
    @media (max-width: 600px) {
        padding: 20px 10px 0px 15px;

    }
`
const Title = styled.div`
    font-size: 2.5em;
    color: ${props => props.theme.grey4};
    margin-bottom: 20px;

    @media (max-width: 600px) {
        font-size: 1.3em;
        margin-bottom: 5px;

    }
`
const SubTitle = styled.div`
    color: ${props => props.theme.grey4};
    font-size: 1.5em;

    @media (max-width: 600px) {
        font-size: 1.2em;

    }
`
const GridBox = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-rows: 250px 250px 250px 250px; */
    grid-auto-rows: 300px;
    margin-top: 50px;
    margin-bottom: 100px;

    @media (max-width: 600px) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
        // grid-auto-flow: row;
        grid-auto-rows: 200px;
        margin-top: 15px;
        margin-bottom: 15px;
        

    }
`
const BannerMain = styled.div`
    background: ${props => props.theme.grey1};
    width: 100%;
    height: 100%;
    padding: 20px;
`
const BannerTxt = styled.div`
    font-size: 2.0em;
    max-width: 700px;
    line-height: 1.2em;
    color: ${props => props.theme.grey6};
    margin-bottom: 90px;

    @media (max-width: 600px) {
        font-size: 1.2em;
        margin-bottom: 40px;
    }
`
const BannerBtn = styled.div`
    cursor: pointer;
    // float: right;
    font-size: 1.6em;
    color: white;
    background: ${props => props.theme.green};
    padding: 20px 0px;
    text-align: center;
    border-radius: 3px;
    width: 200px;

    @media (max-width: 600px) {
        font-size: 1.2em;
        // margin-bottom: 40px;
        width: 150px;
        padding: 10px 0px;
        // margin-bottom: 10px;
    }
`

const QuoteTxt = styled.div`
    font-size: 1.5em;
    max-width: 700px;
    line-height: 1.5em;
    color: white;
    margin-bottom: 50px;

    @media (max-width: 600px) {

        font-size: 1em;
        line-height: 1.1em;
        padding: 5px 0px;
        margin-bottom: 10px;
    }
`
const QuoteAuthor= styled.div`
    // float: right;
    font-size: 1.2em;
    color: white;
    padding: 15px 0px;
    text-align: right;
    width: 100%;

    @media (max-width: 600px) {
        font-size: 0.8em;
        padding: 5px 0px;

    }
`


const ItemQuote = ({bgcolor, text, author}) => {

    const QuoteMain = styled.div`
        background: ${bgcolor};
        width: 100%;
        height: 100%;
        padding: 40px;

        @media (max-width: 600px) {
            padding: 30px 20px 0 20px;
    
        }
    `


    return(
        <QuoteMain>
            <QuoteTxt>
                {text}
            </QuoteTxt>
            <QuoteAuthor>
                {author}
            </QuoteAuthor>
        </QuoteMain>
    
    )

}
const BannerPro = () => (
    <BannerMain>
        <BannerTxt>
            Nossa comunidade está crescendo! Campinas conta com profissionais em todas as regiões.
        </BannerTxt>
        <Link to='/escolas-de-yoga-em-campinas'>
            <BannerBtn>
                Conheça
            </BannerBtn>
        </Link>
    </BannerMain>

)


const FrontPage = () => {

    return(
        <MainFrontStyle>
                <Title>Faça parte dessa comunidade</Title>
                <SubTitle>Participe, aprenda e pratique</SubTitle>
                <GridBox>
                        <ItemCard cardimage="https://firebasestorage.googleapis.com/v0/b/yoga-em-cps.appspot.com/o/artigos-imagens%2Fonde-praticar-yoga-ao-ar-livre-em-campinas-card.jpg?alt=media&token=5e515e46-6109-4580-9ae9-880636cd827d" title='Yoga ao ar livre em Campinas' type='' desc='' link='artigo/onde-praticar-yoga-ao-ar-livre-em-campinas'/>
                    
                        <ItemCard cardimage="https://firebasestorage.googleapis.com/v0/b/yoga-em-cps.appspot.com/o/artigos-imagens%2Fo-que-significa-namaste-card.jpg?alt=media&token=a7b5b8ba-c906-40f9-bc31-82a7ca19e528" title='O que significa Namastê' type='' desc='' link='artigo/o-que-significa-namaste'/>
                   
                        <ItemQuote bgcolor={props => props.theme.orange} text='“Ansiedade tem cura. Existem diversas formas comprovadas para te ajudar nessa jornada' author='por Geise Lima'/>
                    
                        <ItemCard cardimage="https://firebasestorage.googleapis.com/v0/b/yoga-em-cps.appspot.com/o/artigos-imagens%2Fyogues-8-maneiras-de-cuidar-da-saude-de-seus-pes-card.jpg?alt=media&token=acf79199-3dff-4771-85e0-c76c5eb5571b" title='Yogues: 8 maneiras para cuidar de seus pés' link='artigo/yogues-8-maneiras-para-cuidar-da-saude-dos-seus-pes' type='' desc=''/>
                   
                        <ItemCard cardimage="https://firebasestorage.googleapis.com/v0/b/yoga-em-cps.appspot.com/o/artigos-imagens%2Fo-que-sao-pranayamas-cover.jpg?alt=media&token=6ee741ed-5164-421e-b32e-98ba2add0f31" title='O que são Pranayamas e seus Benefícios' type='' desc='' link='artigo/o-que-sao-pranayamas'/>
                    
                        <ItemCard cardimage="https://firebasestorage.googleapis.com/v0/b/yoga-em-cps.appspot.com/o/artigos-imagens%2F5-movimentos-de-yoga-para-relaxar-na-mesa-de-trabalho-cover.jpg?alt=media&token=e742061f-db1c-4493-911e-c0cc15f37ee5" title='Yoga em sua mesa de trabalho' type='' desc='' link='artigo/5-movimentos-de-yoga-para-relaxar-em-sua-mesa-de-trabalho'/>

                </GridBox>

                <BannerPro />
                  
                <GridBox>
                    <ItemQuote bgcolor={props => props.theme.green} text='“Yoga é uma caminhada em direção à saúde, consistência e dedicação é a chave do sucesso.' author='por Elaine Freitas'/>
                
                    <ItemCard cardimage="https://firebasestorage.googleapis.com/v0/b/yoga-em-cps.appspot.com/o/artigos-imagens%2Fohm-mantra-card.jpg?alt=media&token=dcff1242-4cf0-4181-bc80-a7a9de372b0f" title='O que são Mantras' type='' desc='' link='artigo/o-que-sao-mantras'/>
                
                    <ItemCard cardimage="https://firebasestorage.googleapis.com/v0/b/yoga-em-cps.appspot.com/o/artigos-imagens%2F5-beneficios-do-yoga-cover.jpg?alt=media&token=1f2555e6-34d3-448d-ab97-3fb1399e9cc8" title='5 Benefícios do Yoga para melhorar sua vida' type='' desc='' link='artigo/5-beneficios-do-yoga-para-melhorar-a-qualidade-de-vida'/>
                </GridBox>
        </MainFrontStyle>
    )

}

export default FrontPage