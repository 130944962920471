import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle` 


    /* Boilerplate, caso precise */

    body {
        

        h1 {
        
        }

        p {
        
        
        }

        a {

            color: inherit;
        }

        a:hover {

            color: inherit;
        }
    }
    `