import React, { useState }  from 'react';
import styled from 'styled-components'
import { Dropdown, Button, Icon} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import logoURL   from '../../../Assets/logo/logoyec.png';
import firebase from 'firebase/app'
import { FirebaseAuthConsumer } from "@react-firebase/auth";


const NavStyle = styled.div`
    // O motivo desse position e z-index é só por causa da página de republicas
    position: relative;
    z-index: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    height: 70px;
    font-family: ${props => props.theme.font};
    width: 100vw;
`

const Logo = styled.img`
    margin: 15px 5px 5px 0px;
    width: 60px;
`
const MenuIconPosition = styled.div`
    padding-left: 100px;
`


const NavMobile = () => {
    const [activeItem, setActiveItem] = useState('Inicial');


    return (
        <FirebaseAuthConsumer>
  		{({ isSignedIn, user, providerId }) => (
            <NavStyle>
                
            <Link to='/' name='Inicial'><Logo src={logoURL} alt="logo"/></Link>

            
                

                
                <Dropdown trigger={<Icon name='bars' color='orange'/>} icon={null} direction='left' >

                    <Dropdown.Menu>
                    {user && user.uid &&
                        <Dropdown.Header>{user.displayName}</Dropdown.Header>
                    }
                        <Dropdown.Item as={Link} to='/'>
                            Inicial
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to='/artigos'>
                            Artigos
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to='#'>
                            Profissionais
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to='/escolas-de-yoga-em-campinas'>
                            Escolas
                        </Dropdown.Item>
                        {user && user.uid &&
                        <Dropdown.Item>
                            <Button onClick={() => { firebase.auth().signOut();}} size='tiny'>
                                Sair
                            </Button>
                        </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
                
            


        </NavStyle>
      )}
      </FirebaseAuthConsumer>
    )
}

export default NavMobile;