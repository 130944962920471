import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import {Helmet} from "react-helmet";
import firebase from 'firebase/app';
import 'firebase/storage'
require("firebase/firestore");
const ReactMarkdown = require('react-markdown')



const MainStyle = styled.div`
    background: white;
	min-height: 400px;
    margin: 0px 0px 10px 0px;
    padding: 30px;
    color: ${props => props.theme.color};
    font-family: ${props => props.theme.font};
`
const Columns = styled.div`
   display: flex;
`
const ColumnLeft = styled.div`
   width: 20%;
`
const MenuBox = styled.div`
    position: sticky;
    top: 0px;
    padding-top: 20px;
    width: 100%;
    min-height: 500px;
    /* background: lightgrey; */
`
const ColumnRight = styled.div`
   width: 80%;
   height: auto;
`

const CurrentReading = styled.div`
    padding-right: 10px;
    margin-bottom: 50px;
    max-width: 230px;
`
const ReadTitle = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
`
const ReadDesc = styled.div`
    font-size: 15px;
    color: ${props => props.theme.grey6};
    /* text-transform: uppercase; */
    /* font-weight: 500; */
`

const ContentTitle = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 5px;
`
const TableOfContent = styled.div`
    font-size: 13px;
        & {
            ul {
                
                // line-height: 28px;
                font-family: ${props => props.theme.font};
                padding-left: 10px;
                margin-left: 10px;
                
            }
            ol {
                // font-size: 16px;
                // line-height: 28px;
                font-family: ${props => props.theme.font};
                padding-left: 10px;
                margin-left: 10px;
            }
        }
`


const CanvasArticle = styled.div`
   padding: 10px 40px 20px 40px;
   width: 800px;
        & {
            h1 {
                font-size: 35px;
                font-weight: 600;
                font-family: ${props => props.theme.font};
            }
            .subtitle {
                font-family: ${props => props.theme.font};
                color: ${props => props.theme.orange};
                font-style: italic;
                font-size: 20px;
                font-weight: 400;
                margin-top: -10px;
                margin-bottom: 40px;
            }
            h2 {
                margin-top: 50px;
                font-family: ${props => props.theme.font};
                color: ${props => props.theme.grey7};
            }
            h3 {
                font-family: ${props => props.theme.font};
            }
            p {
                font-size: 16px;
                line-height: 28px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            blockquote {
                border-left: 5px solid ${props => props.theme.orange};
                padding-left: 10px;
                font-size: 16px;
                margin-left: 0px;
            }
        
            ul {
                font-size: 16px;
                line-height: 28px;
                font-family: ${props => props.theme.font};
                padding-left: 10px;
                margin-left: 10px;
                
            }
            ol {
                font-size: 16px;
                line-height: 28px;
                font-family: ${props => props.theme.font};
                padding-left: 10px;
                margin-left: 10px;
            }

            hr {
                border: 1px solid ${props => props.theme.grey1};
                margin-top: 50px;
                margin-bottom: 50px;
            }
            li {
                padding-left: 10px;
            }
            figcaption {
                margin-top: -15px;
                text-align: center;
                margin-bottom: 20px;
                font-size: 13px;
            }
            img {
                width: 100%;
            }

            
        }
`




const ArticleView = ({match}) => {
    const [artigo, setArtigo] = useState('');
    // const [artigoText, setArtigoText] = useState('');

    let articleId = match.params.id || ''
    // console.log(articleId)

    useEffect(() => {

        window.scrollTo(0, 0)

        // var storage = customApp.storage();

        // var storage = firebase.storage();
        // var artigosRef = storage.ref('noticias');
        
		// var artigoFile = artigosRef.child(`${match.params.id}/${match.params.id}.md`)

		// artigoFile.getDownloadURL().then(function(url) {
		// 	fetch(url)
		// 	.then((response) => response.text())
		// 	.then((text) => {
        //     setArtigoText(text)
		// 	})
			
					
		// })


        let noticiasRef = firebase.firestore().collection("artigos")
        noticiasRef.where('id', '==', articleId).get().then((snapshot) => {
            snapshot.forEach((doc) => {
                let artigo = doc.data()
                if (artigo) {
                    setArtigo(artigo)
                }
            });
            
        })


    },[]);



    return(
        <React.Fragment>
            <MainStyle>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{artigo.title + ' - Yoga em Campinas'}</title>
                    {/* <link rel="canonical" href="http://mysite.com/example" /> */}
                </Helmet>
                <Columns>
                    <ColumnLeft>
                        <MenuBox>
                        {match.params.id &&
                            <CurrentReading>
                                <ReadTitle>Você está lendo:</ReadTitle>
                                <ReadDesc>{artigo.title}</ReadDesc>
                                
                                {/* <ContentTitle>Conteúdo</ContentTitle>
                                <TableOfContent>
                                    <ReactMarkdown source={artigo.menu} escapeHtml={false} skipHtml={false} />
                                </TableOfContent> */}
                            </CurrentReading>
                        }
                            {/* <ArticleMenu /> */}
                        </MenuBox>
                    </ColumnLeft>
                    <ColumnRight>
                        
                    
                    <CanvasArticle>
                        <ReactMarkdown source={artigo.textmd} escapeHtml={false} skipHtml={false} />
                    </CanvasArticle>

                     
                    

                    </ColumnRight>
                </Columns>
                
            </MainStyle>
        </React.Fragment>
        )
}

export default ArticleView